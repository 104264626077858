import React from "react"
import {
  ChildContainer,
  Description,
  ParentContainer,
  Title,
  Image,
  TitleSub,
  SubText,
  Ul,
  LearnMore,
  SvgHolder,
  TitleHolder,
} from "../../../ToolsStyles/UsefulToolsStyled"
import DummyImage from "../../MergeAssets/gradient_24px.png"
import Rotate from "../../../../../icons/tools/lp/Rotate.svg"
import Extract from "../../../../../icons/tools/lp/extract.svg"
import Delete from "./icons/delete.svg"
const UsefulTools = () => {
  return (
    <ParentContainer>
      <TitleHolder>
        <Title>Continue to organize this PDF </Title>
        <Description>
          Here are a couple other tools that you may want to use on your current
          PDF document.
        </Description>
        <LearnMore to="/pdf-online-tools/">
          View all Online tools &rarr;
        </LearnMore>
      </TitleHolder>

      <ChildContainer>
        <SvgHolder>
          <Rotate />
        </SvgHolder>

        <TitleSub>Rotate PDF</TitleSub>

        <SubText>Rotate individual PDF pages or entire documents.</SubText>
        <LearnMore to="/rotate-pdf/">Continue &rarr;</LearnMore>
      </ChildContainer>

      <ChildContainer>
        <SvgHolder>
          <Delete />
        </SvgHolder>

        <TitleSub>Delete PDF Pages</TitleSub>

        <SubText>Delete pages from a PDF.</SubText>
        <LearnMore to="/delete-pdf-pages/">Continue &rarr;</LearnMore>
      </ChildContainer>
    </ParentContainer>
  )
}

export default UsefulTools
