import React from "react"
import ReactDOMServer from "react-dom/server"
import PdfdisplayMerge from "../merge/Pdfdisplay"

export default function displayPdfSyncMerge(
  pdfData,
  fileData,
  appendTo,
  order,
  numPages,
  setFileRendered,
  removePassProtected,
  setErrorFiles,
  repoLength,
  password,
  pdfInput,
  files,
  position
) {
  return new Promise(async (resolve, reject) => {
    console.log("Position: ", position)

    let protectedPass = false

    const pdf = await window.pdfjsLib
      .getDocument({
        data: pdfData,
        password,
      })
      .promise.catch(() => {
        console.log("Password protected: ", fileData.name)
        // removePassProtected(fileData.name)
        // setErrorFiles(erF => [...erF, fileData.name])
        protectedPass = true

        let Prompt = document.getElementById("PasswordPrompt")
        let Enter = document.getElementById("EnterPassword")
        let PdfPasswordInput = document.getElementById("PdfPasswordInput")
        let PasswordFileName = document.getElementById("PasswordFileName")
        if (PasswordFileName) PasswordFileName.innerText = fileData.name
        if (PdfPasswordInput)
          PdfPasswordInput.value = window.password ? window.password : ""
        if (Prompt) Prompt.style.display = "flex"

        if (PdfPasswordInput && window.password) {
          PdfPasswordInput.style.outline = "2px solid rgba(253, 105, 103, 0.3)"
          PdfPasswordInput.style.border = "1px solid #FD6967"
        }

        if (Enter && window.password) {
          Enter.style.background = "#FD6967"
        }

        // This is hendling double event listeners that refues to be removed
        if (!window.order) window.order = 0
        window.order = window.order + 1
        let order = window.order

        function clickListener(event) {
          if (order == window.order) {
            Prompt.style.display = "none"
            window.password = PdfPasswordInput.value
            console.log("pass: ", PdfPasswordInput.value)
            // PdfPasswordInput.value = ""
            console.log("event listener;", order)
            PdfPasswordInput.style.outline = "2px solid #badaef"
            PdfPasswordInput.style.border = " 1px solid #6361ff"
            Enter.style.background = "#6361ff"

            displayPdfSyncMerge(
              pdfData,
              fileData,
              appendTo,
              order,
              numPages,
              setFileRendered,
              removePassProtected,
              setErrorFiles,
              repoLength,
              PdfPasswordInput.value,
              pdfInput,
              files,
              position
            )
          }
        }

        function keyboardListener(event) {
          if (event.key === "Enter" && order == window.order) {
            event.preventDefault()
            window.password = PdfPasswordInput.value
            console.log("pass: ", PdfPasswordInput.value)

            // PdfPasswordInput.value = ""
            Prompt.style.display = "none"
            console.log("event listener;", order)
            PdfPasswordInput.style.outline = "2px solid #badaef"
            PdfPasswordInput.style.border = " 1px solid #6361ff"
            Enter.style.background = "#6361ff"
            displayPdfSyncMerge(
              pdfData,
              fileData,
              appendTo,
              order,
              numPages,
              setFileRendered,
              removePassProtected,
              setErrorFiles,
              repoLength,
              PdfPasswordInput.value,
              pdfInput,
              files,
              position
            )
          }
        }

        Enter.addEventListener("click", clickListener)

        PdfPasswordInput.addEventListener("keydown", keyboardListener)
      })

    window.realPass = window.password
    window.password = undefined

    if (protectedPass) {
      return resolve(false)
    }

    const pages = numPages ? numPages : pdf.numPages

    for (let i = 0; i < pages; i++) {
      const page = await pdf.getPage(i + 1)
      const viewport = page.getViewport({ scale: 0.337 })

      const canvas = document.createElement("canvas")
      const div = document.createElement("div")

      const context = canvas.getContext("2d")
      canvas.height = viewport.height
      canvas.width = viewport.width
      canvas.style.marginDown = "64px"

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }

      await page.render(renderContext).promise

      var dataURL = canvas.toDataURL()

      let str = JSON.stringify({ name: fileData.name, size: fileData.size })

      document.getElementById(
        appendTo
      ).innerHTML += ReactDOMServer?.renderToString(
        <PdfdisplayMerge
          element={dataURL}
          name={fileData.name}
          pages={pdf.numPages}
          file={str}
          order={repoLength + order}
          password={password}
        >
          {" "}
          test{" "}
        </PdfdisplayMerge>
      )
    }

    if (!protectedPass) {
      setFileRendered(position + 1)
      console.log(files.length, ">", position + 1)
      if (files.length > position + 1) pdfInput(files, position + 1)
    } else {
      // setFileRendered(window.position ? window.position : 0)

      pdfInput(files, position)
    }
    return resolve(true)
  })
}
