import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
import { set } from "lodash"

export const MergeFiles = (
  data,
  setDownload,
  setUploadPct,
  setProcessPct,
  setDownloadPct
) =>
  client
    .post("/uploadNew", data.formData, {
      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setUploadPct(percentCompleted)
      },
    })
    .then(res => {
      DataLayer("Online tool", "Upload", "Merge")
      window.files = res.data.message

      fetch("https://backendtesting.link/mergeTool", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileNames: res.data.message,
          fileValues: data.fileValues,
        }),
      })
        .then(res => {
          DataLayer("Online tool", "Merge", "Merge")
          const reader = res.body.getReader()

          reader.read().then(function processResult({ done, value }) {
            if (done) {
              setProcessPct(100)
              return
            }
            const textChunk = new TextDecoder("utf-8").decode(value)
            const data = JSON.parse(textChunk)
            console.log("percentage process: ", data, " %")
            return reader.read().then(processResult)
          })

          client
            .get(
              `/downloadMerge?fileName=${encodeURIComponent(
                JSON.stringify(window.files)
              )}`,
              {
                responseType: "blob",
                onDownloadProgress: progressEvent => {
                  var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  )
                  setDownloadPct(percentCompleted)
                },
              }
            )
            .then(res => {
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = "pdfpro_merged.pdf"
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Merge")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Merge")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error merge", "Merge")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Merge")
    })
