import React, { useState, useEffect, useRef, useContext } from "react"
import { GlobalAuthContext } from "../../../Contexts/GlobalAuthContext"
import styled from "styled-components"
import RestartIcon from "../../../icons/tools/Restart.svg"
import displayPdfSyncMerge from "../helpers/displayPdf-Merge"
import { FileUploader } from "react-drag-drop-files"
import TryPdf from "../../../components/shared/TryPdf"
import Add from "../../../icons/tools/Add.svg"
import ArrowRight from "../../../icons/tools/arrow-right.svg"
import MergeIcon from "../../../icons/tools/Merge_icon.svg"
import MergedIcon from "../../../icons/tools/mergedIcon.svg"
import ErrorMessage from "../../../components/tools/ErrorMessage"
import UploadIcon from "../../../icons/tools/upload.svg"
import AddSimple from "../../../icons/tools/addSimple.svg"
import ArrowTry from "../../../icons/tools/tryPdfArrow.svg"
import PrivacyQuestion from "../../../icons/tools/PrivacyQuestion.svg"
import Title from "../GlobalToolComponents/ToolH2tag"
import UsefulTools from "./Landing/UsefulTools/UsefulTools"
import { MergeFiles } from "../../../components/tools/merge/Actions"
import Warning from "../Warning"
import {
  DownloadScreen,
  CanvasStyle,
  LoadingScreen,
  Pdfpreview,
} from "../Styles"
import Disclaimer from "../GlobalToolComponents/Disclaimer"
import GDPRwarning from "../GlobalToolComponents/GDPRwarning"
import ToolLoader from "../ToolsStyles/ToolLoader"
import TrialMessage from "../ToolsStyles/TrialMessage"

const LoadingCircle = styled.div`
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const FileUploaderStyle = styled.div`
  margin: auto;
  max-width: 532px;
  width: 100%;
  margin-top: 60px;

  .drop_zone {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed red;
    width: 100%;
    min-height: 374px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    margin: 8px 0;
  }

  :hover {
    max-width: 548px;
    .drop_zone {
      min-height: 390px;
      background: #ebeeff;
      margin: 0;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 18px;
      line-height: 28px;
      color: #425466;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 8px;
      :after {
        content: "Drag and drop your PDF file here";
      }
      @media (max-width: 744px) {
        :after {
          content: "Tap to upload PDF file here";
        }
      }
    }
    .info {
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #6361ff;
      text-transform: capitalize;

      :after {
        content: "PDF files smaller than 20MB, max 30 files";
      }
      @media (max-width: 744px) {
        :after {
          content: "PDF files smaller than 20MB, max 30 files";
        }
      }
    }

    button {
      all: unset;
      margin-top: 40px;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      padding: 12px 24px;
      gap: 12px;
      background: #6361ff;
      border-radius: 8px;
      cursor: pointer;
      @media (max-width: 744px) {
        display: none;
      }
    }
  }
`
const Offline = styled.div`
  background: #e3e8ee;
  border-radius: 2px;
  width: 73px;
  height: 26px;
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;
  color: #6a7485;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  :hover {
    .warning {
      opacity: 1;
    }
  }
`

const ToPdf = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  a {
    color: #6361ff;
    text-decoration: none;
  }

  .desktop {
    display: inline-block;
    @media (max-width: 565px) {
      display: none;
    }
  }
`
const CtaPopup = styled.div`
  color: #6361ff;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  white-space: nowrap;
`
const SingleFileUploadStyle = styled.div`
  min-width: 205px;
  max-height: 267px;

  width: max-content;
  margin: 0;
  margin-bottom: 66px;
  display: inline-block;

  .drop_zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 267px;
    background: #f5f6fc;
    ${props => props.disabled && "background: hsla(0, 0%, 0%, 0.1);"}
    border: 1px dashed #6361ff;
    ${props => props.disabled && "border: 1px dashed hsla(0, 0%, 0%, 0.3);"}

    border-radius: 2px;
    cursor: pointer;
    ${props => props.disabled && "cursor: auto;"}

    :hover {
      background: #ebeeff;
      ${props => props.disabled && "background: hsla(0, 0%, 0%, 0.1);"}
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #6361ff;
      ${props => props.disabled && "color: hsla(0, 0%, 0%, 0.3);"}

      font-weight: 800;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .info {
      max-width: 97px;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      color: #6361ff;
      ${props => props.disabled && "color: hsla(0, 0%, 0%, 0.3);"}

      white-space: pre-wrap;
    }
    path {
      ${props => props.disabled && "stroke: hsla(0, 0%, 0%, 0.3) !important;"}

      ${props => props.disabled && "fill: hsla(0, 0%, 0%, 0.1) !important;"}
    }
  }
`

const Pdfholder = styled.div`
  min-height: 283px;
  display: flex;
  align-items: flex-end;
  gap: 48px;
  padding-top: 32px;
`

const MergeButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  width: 170px;
  white-space: nowrap;
  transition: background-color 1s;
  height: 48px;
  background: #6361ff;
  border-radius: 8px;

  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;

  svg {
    min-width: 24px;
    min-width: 24px;
  }
  ${props => props.disabled && "background: #CDCDCD;"}

  @media (max-width: 440px) {
    width: 90%;
  }
`

const AddFile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  width: 147px;
  height: 48px;
  background: #f5f9fc;
  border: 2px solid #6361ff;
  border-radius: 8px;
  font-size: 17px;
  color: #6361ff;
  white-space: nowrap;
  cursor: pointer;
  svg {
    min-width: 24px;
    min-width: 24px;
  }
  @media (max-width: 440px) {
    width: 90vw;
  }
  ${props => props.disabled && "color: hsla(0, 0%, 0%, 0.3);"}

  ${props => props.disabled && "background: hsla(0, 0%, 0%, 0.1);"}

    ${props => props.disabled && "border: 1px dashed hsla(0, 0%, 0%, 0.3);"}

    ${props => props.disabled && "cursor: auto;"}

    :hover {
    ${props => props.disabled && "background: hsla(0, 0%, 0%, 0.1);"}
  }

  svg {
    ${props => props.disabled && "fill: hsla(0, 0%, 0%, 0.3);"}
    ${props => props.disabled && "stroke: hsla(0, 0%, 0%, 0.3);"}
  }
  path {
    ${props => props.disabled && "fill: hsla(0, 0%, 0%, 0.3);"}
    ${props => props.disabled && "stroke: hsla(0, 0%, 0%, 0.3);"}
  }
`

const SecondScreen = styled.div`
  /* ${props => props.hide && "display: none;"} */
  /* transform: translateY(124px); */
`

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;

  @media (max-width: 440px) {
    margin-top: 96px;

    flex-direction: column;
    align-items: center;
  }
  max-height: 0;
  overflow: visible;
`

const Info = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #425466;
  max-height: 0;
  overflow: visible;
  margin-top: 75px;

  @media (max-width: 440px) {
    display: none;
  }
`
const Message = styled.div`
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #425466;
  margin-bottom: 32px;
  @media (max-width: 744px) {
    display: none;
  }
`
const Loading = styled.div`
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #333333;
  margin-bottom: 24px;
`

const Percent = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #8d9092;
  margin-top: 8px;
`

const BarBack = styled.div`
  width: 320px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
`

const BarButton = styled.div`
  ${props => `width: ${320 * props.percent}px;`}
  background: #6361FF;
  height: 8px;

  border-radius: 4px;
`

const DownloadTitle = styled.div`
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 24px 0;
  @media (max-width: 744px) {
    font-size: 32px;
    line-height: 32px;
  }
`

const DownloadMessage = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #425466;
  @media (max-width: 744px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const DownloadButton = styled.div`
  min-width: 156px;
  height: 48px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #6361ff;
  border-radius: 8px;
  margin-top: 40px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 440px) {
    width: 100%;
  }
`

const ErrorMessageHolder = styled.div`
  background: red;
  height: 100%;
  width: 0px;
  position: absolute;
  left: 50%;
  bottom: 0;
  padding-bottom: 258px;
  padding-top: 258px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 744px) {
    justify-content: flex-start;
  }
`

const Restart = styled(ToPdf)`
  margin-top: auto;
  display: flex;
  margin-bottom: 32px;
  margin-top: 32px;
`

const CtaRestat = styled(CtaPopup)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 8px;
  }
`

function MergeTool() {
  const [file, setFile] = useState(null)
  const [errorFiles, setErrorFiles] = useState([])
  const [repository, setRepository] = useState([])
  const [fileRendered, setFileRendered] = useState(0)
  const [dragElement, setDragElement] = useState()
  const [filesSize, setFilesSize] = useState(0)
  const [filesSizeNum, setFilesSizeNum] = useState(0)
  const [download, setDownload] = useState(null)
  const [downloadPct, setDownloadPct] = useState(0)
  const [uploadPct, setUploadPct] = useState(0)
  const [processPct, setProcessPct] = useState(0)
  const [errors, setErrors] = useState([])
  const [showAdd, setShowAdd] = useState(false)

  const [deleteFile, setDeleteFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const pdfHolder = useRef(null)
  const pdfPreview = useRef(null)

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  useEffect(() => {
    if (deleteFile) {
      setRepository(rep => {
        let rawRep = []
        rep.filter(r => {
          if (r.name != deleteFile.name) {
            rawRep.push(r)
          }
        })
        return [...rawRep]
      })

      setDeleteFile(null)
    }
  }, [deleteFile])

  useEffect(() => {
    let size = 0
    repository.forEach(f => {
      size += f.size
    })

    setFilesSize(formatBytes(size, 2))
  }, [repository])

  useEffect(() => {
    if (pdfHolder?.current) {
      function detectScrollability() {
        if (pdfPreview.current.scrollWidth > pdfPreview.current.clientWidth) {
          pdfPreview.current.style.justifyContent = "normal"
          setShowAdd(true)
        } else {
          pdfPreview.current.style.justifyContent = "center"
          setShowAdd(false)
        }
      }
      window.addEventListener("resize", () => {
        detectScrollability()
      })
      const observer = new MutationObserver(detectScrollability)

      detectScrollability()
      observer.observe(pdfPreview.current, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    }
  }, [pdfHolder.current])

  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    setMyPopUp("show")
    setScroll("show")
  }

  useEffect(() => {
    if (file) {
      const parent = document.getElementById("canvas-storage")
      const observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            const addedNodes = mutation.addedNodes
            let i = 1
            for (let node of addedNodes) {
              if (node.nodeType === Node.ELEMENT_NODE) {
                // do something when the element is fully rendered
                const deleteButton = node.firstChild.children[1]
                deleteButton.addEventListener("click", e => {
                  const button = deleteButton
                  if (button.parentNode.parentNode.parentNode) {
                    let file = JSON.parse(button.getAttribute("file"))
                    setDeleteFile(file)
                    button.parentNode.parentNode.parentNode.removeChild(
                      button.parentNode.parentNode
                    )
                  }
                })
              }
              i++
            }
          }
        }
      })

      observer.observe(parent, { childList: true })
    }
  }, [file])

  useEffect(() => {
    if (file?.length == fileRendered && fileRendered != 0) {
      setFileRendered(0)
      setLoading(false)

      let filterFiles = []

      for (const [key, value] of Object.entries(file)) {
        let errored = false
        errorFiles.forEach(erF => {
          if (erF === value.name) {
            errored = true
          }
        })
        if (!errored) {
          filterFiles.push(value)
        }
      }

      if (filterFiles.length == 0 && repository.length == 0) {
        setFile(null)
      }

      setRepository(rep => {
        return [...rep, ...filterFiles]
      })

      // setTimeout(SetEvents, 2000)
    }
  }, [fileRendered])

  function removePassProtected(fileName) {
    let str = fileName + " is password protected"
    ErrorPopup(str)
  }

  useEffect(() => {
    console.log("File: ", file)
  }, [file])

  async function pdfInput(file, order) {
    console.log("File length: ", file?.length)
    let value = file[order]

    const fileReader = new FileReader()
    fileReader.onload = async function () {
      const pdfData = new Uint8Array(this.result)
      await displayPdfSyncMerge(
        pdfData,
        value,
        "canvas-storage",
        order,
        1,
        setFileRendered,
        removePassProtected,
        setErrorFiles,
        repository.length,
        "",
        pdfInput,
        file,
        order
      )
    }
    fileReader.readAsArrayBuffer(value)

    // if (fileRendered + 1 < file.length) {
    //   pdfInput(file)
    // }
  }

  const fileTypes = ["pdf"]

  const handleChange = async file => {
    if (file?.length + repository?.length < 31) {
      const formData = new FormData()
      formData.append("excel", file)
      setLoading(true)

      setFile(file)
      await pdfInput(file, fileRendered)
    } else {
      ErrorPopup("You can't have more than 30 files at once")
    }
  }

  const filesSubmit = () => {
    if (repository.length > 1) {
      const formData = new FormData()
      const pdfdisplays = document.querySelectorAll(".pdfdisplay")
      let passArray = []

      pdfdisplays.forEach(p => {
        let pass = p.getAttribute("data-pass")
        console.log("Pass: ", p.getAttribute("data-pass"))
        passArray.push(pass)
      })
      console.log("Pass arrays: ", passArray)
      let fileNames = []
      let fileValues = []

      repository.forEach(r => {
        formData.append(r.name, r)
        fileNames.push(r.name)
        fileValues.push("")
      })

      let data = {
        formData: formData,
        fileNames: fileNames,
        fileValues: fileValues,
      }
      setDownload(true)
      MergeFiles(data, setDownload, setUploadPct, setProcessPct, setDownloadPct)
    } else {
      ErrorPopup("You can't merge only one file")
    }
  }
  function restartTool(e) {
    window.location.reload()
  }
  function ErrorPopup(message) {
    let oldEr = [...errors, message]
    setErrors(er => [...er, message])
    setTimeout(() => {
      if (
        oldEr.length == errors.length &&
        JSON.stringify(oldEr) == JSON.stringify(errors)
      ) {
        setErrors([])
      }
    }, 5000)
  }
  if (download != null) {
    return (
      <DownloadScreen>
        <MergedIcon />
        {download === true && (
          <DownloadTitle>Your PDFs are now being merged</DownloadTitle>
        )}

        {download !== true && (
          <DownloadTitle>Your PDF files have been merged</DownloadTitle>
        )}
        <DownloadMessage>
          Your PDF file will automatically download once it is ready. If it
          doesn't, use the button below.{" "}
        </DownloadMessage>
        <ToolLoader
          uploadPct={uploadPct}
          processPct={processPct}
          downloadPct={downloadPct}
          processMessage={"Merging... "}
          finishedMessage={"Merged!"}
        />
        <TrialMessage openPopup={openPopup} />
        {download === true && (
          <DownloadButton>
            <LoadingCircle />
            Processing...
          </DownloadButton>
        )}
        {download !== true && (
          <DownloadButton onClick={() => download.click()}>
            Download File
          </DownloadButton>
        )}
        <Restart>
          <div className="desktop">Need to convert another file?</div>
          <CtaRestat onClick={restartTool}>
            <RestartIcon /> Start Over{" "}
          </CtaRestat>
        </Restart>
        <UsefulTools />
        {myPopUp === "show" && <TryPdf close={closePopup} />}
      </DownloadScreen>
    )
  }

  return (
    <CanvasStyle>
      <ErrorMessageHolder>
        {errors.map(m => {
          return <ErrorMessage message={m}></ErrorMessage>
        })}
      </ErrorMessageHolder>
      {!file && (
        <div className="firstScreen">
          <MergeIcon />
          <h1 id="MergePDF">Merge PDFs Online</h1>
          <Title>Easily Merge PDFs Online for Free</Title>
          <FileUploaderStyle>
            <FileUploader
              handleChange={handleChange}
              multiple={true}
              types={fileTypes}
              maxSize={20}
              onSizeError={() => {
                ErrorPopup("Your file needs to be under 5MB size")
              }}
              classes="drop_area drop_zone "
              children={
                <div className="column">
                  <UploadIcon />
                  <div className="title"></div>
                  <div className="info"></div>
                  <button>Upload file</button>
                </div>
              }
            />
          </FileUploaderStyle>
          <Row>
            <Offline>Offline</Offline>
            <ToPdf>
              <div className="desktop">Rather work offline?&nbsp;</div>
              <CtaPopup onClick={openPopup}>
                Try PDF Pro for free today! <ArrowTry />{" "}
              </CtaPopup>
            </ToPdf>
          </Row>
          <Row>
            <Warning />
            <GDPRwarning>
              <Disclaimer /> <PrivacyQuestion />
            </GDPRwarning>
          </Row>
          {myPopUp === "show" && <TryPdf close={closePopup} />}
        </div>
      )}
      <LoadingScreen hide={loading}>
        <Loading>Loading... </Loading>
        <BarBack>
          <BarButton percent={fileRendered / file?.length} />{" "}
        </BarBack>
        <Percent>{((fileRendered / file?.length) * 100).toFixed(0)}%</Percent>
      </LoadingScreen>
      {file && (
        <SecondScreen>
          {repository.length < 2 && (
            <Message>Please, select one more PDF file to merge </Message>
          )}
          {repository.length > 1 && (
            <Message>When you're ready, press Merge PDF </Message>
          )}

          <Pdfpreview ref={pdfPreview}>
            <Pdfholder id="canvas-storage" ref={pdfHolder}>
              {" "}
            </Pdfholder>
            <SingleFileUploadStyle disabled={repository?.length >= 30}>
              <FileUploader
                handleChange={handleChange}
                multiple={true}
                types={fileTypes}
                disabled={repository?.length >= 30}
                maxSize={20}
                onSizeError={() => {
                  ErrorPopup("Your file needs to be under 20MB size")
                }}
                classes="drop_area drop_zone "
                children={
                  <div className="column">
                    <Add />
                    <div className="title">Add File</div>
                    <div className="info">
                      PDF files smaller than 20MB, max 30 files
                    </div>
                  </div>
                }
              />
            </SingleFileUploadStyle>
          </Pdfpreview>

          <ButtonHolder>
            {showAdd && (
              <FileUploader
                handleChange={handleChange}
                multiple={true}
                types={fileTypes}
                maxSize={20}
                // disabled={repository?.length >= 30}
                onSizeError={() => {
                  ErrorPopup("Your file needs to be under 20MB size")
                }}
                classes="drop_area drop_zone "
                children={
                  <AddFile disabled={repository?.length >= 30}>
                    {" "}
                    <AddSimple />
                    Add File
                  </AddFile>
                }
              />
            )}
            <MergeButton
              disabled={!(repository.length > 1)}
              onClick={filesSubmit}
            >
              Merge PDF <ArrowRight />
            </MergeButton>
          </ButtonHolder>
          <Info>
            {repository.length} files, {filesSize}
          </Info>
        </SecondScreen>
      )}
    </CanvasStyle>
  )
}

export default MergeTool
