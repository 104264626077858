import React from "react"
import {
  Container,
  Title,
  Description,
  DescriptionBox,
  ImageContainter,
  SvgHolder,
} from "../../../ToolsStyles/ShortDescriptionStyled"
import PDFframe from "../../MergeAssets/MergePDF.jpg"

import Desc1 from "../../../../../icons/tools/Merg1.svg"
import Desc2 from "../../../../../icons/tools/QuickandEasy.svg"
import Desc3 from "../../../../../icons/tools/lp/merge/DescIcon3.svg"
import Desc4 from "../../../../../icons/tools/lp/merge/DescIcon4.svg"
import Desc5 from "../../../../../icons/tools/lp/merge/DescIcon5.svg"
import Desc6 from "../../../../../icons/tools/lp/merge/DescIcon6.svg"

const ShortDescriptions = () => {
  return (
    <Container>
      <DescriptionBox>
        <SvgHolder>
          <Desc1 />
        </SvgHolder>
        <Title>Combine And Organize PDFs</Title>
        <Description>
          Create a new PDF by uploading two PDFs to merge together. Organize
          documents, streamline your work, and more.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc2 />
        </SvgHolder>
        <Title>Quick and Easy</Title>
        <Description>
          This PDF merger is easy to use and will quickly produce your newly
          merged document.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc3 />
        </SvgHolder>
        <Title>Safe and Secure</Title>
        <Description>Uploaded files are deleted after 24 hours.</Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc4 />
        </SvgHolder>
        <Title>100% Free</Title>
        <Description>
          Our PDF merger is totally free! No limits on documents or tasks. Merge
          PDFs to your heart’s content.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          {" "}
          <Desc5 />
        </SvgHolder>
        <Title>No installation or sign-up required</Title>
        <Description>
          Simply upload your documents and start merging! No downloading or
          signing up necessary.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          {" "}
          <Desc6 />
        </SvgHolder>
        <Title>Works on all systems</Title>
        <Description>
          Our online PDF merger is designed to work on all systems, from your
          browser.
        </Description>
      </DescriptionBox>
    </Container>
  )
}

export default ShortDescriptions
