import React, { useEffect } from "react"
import styled from "styled-components"
import Trash from "../../../icons/tools/trash.svg"

const PdfdisplayStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 283px;
  min-width: max-content;
  width: 100%;
  max-width: max-content;
  /* transition: order 1s; */
  /*test*/
  order: 10;
  img {
    max-width: max-content;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: border 0.5s, outline 0.5s;
    outline: rgba(216, 219, 253, 0) solid 10px;
    box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
      0px 8px 16px -8px rgba(0, 0, 0, 0.3);

    :hover {
      outline: rgba(216, 219, 253, 0.5) solid 10px;

      border: 1px solid #425466;
    }
  }
`
const Name = styled.div`
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0b263f;
  margin-top: 16px;
  max-width: 200px;
  min-height: 24px;
  /* max-height: 24px; */
  display: block;
  white-space: normal;

  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.3s;
  :hover {
    z-index: 100;
    text-overflow: initial;
    display: initial;
    -webkit-line-clamp: none;
    -webkit-box-orient: initial;
    overflow: initial;
    height: 72px;
    width: max-content;
    cursor: pointer;
  }
`

const Pages = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #425466;
  margin-top: 4px;
  width: 100%;
  text-align: center;
`

const ImageHolder = styled.div`
  position: relative;
  cursor: move;

  svg {
    transition: opacity 0.2s;
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  :hover {
    svg {
      opacity: 1;
    }
  }
`

function PdfdisplayMerge({ element, name, pages, file, order, password }) {
  return (
    <PdfdisplayStyle
      className="pdfdisplay"
      id={`pdfdisplay` + order}
      data-pass={password}
    >
      <ImageHolder>
        <img src={element} alt="Pdf display" draggable="false" />
        <Trash className="delete" id={`delete` + order} file={file} />
      </ImageHolder>
      <Name>{name} </Name>
      <Pages>{pages} pages</Pages>
    </PdfdisplayStyle>
  )
}

export default PdfdisplayMerge
